@font-face {
  font-family: 'ElproIcon';
  src:  url('fonts/ElproIcon.eot?ohe0wn');
  src:  url('fonts/ElproIcon.eot?ohe0wn#iefix') format('embedded-opentype'),
  url('fonts/ElproIcon.ttf?ohe0wn') format('truetype'),
  url('fonts/ElproIcon.woff?ohe0wn') format('woff'),
  url('fonts/ElproIcon.svg?ohe0wn#ElproIcon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="elpro-"], [class*=" elpro-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ElproIcon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.elpro-Acknowledge:before {
  content: "\e900";
}
.elpro-Add:before {
  content: "\e901";
}
.elpro-AlarmAcknowledged .path1:before {
  content: "\e902";
  color: rgb(110, 110, 110);
}
.elpro-AlarmAcknowledged .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.elpro-AlarmAcknowledged .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.elpro-AlarmActive .path1:before {
  content: "\e905";
  color: rgb(228, 25, 25);
}

/*latch */
.elpro-latch-Alarm .path1:before {
  content: "\2612";
  color: rgb(58, 58, 57);
}
/*-----*/

.elpro-AlarmActive .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.elpro-AlarmActive .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.elpro-AlarmInactive .path1:before {
  content: "\e908";
  color: rgb(110, 110, 110);
}
.elpro-AlarmInactive .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(110, 110, 110);
}
.elpro-AlarmInactive .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(110, 110, 110);
}
.elpro-AlarmInactive .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-AuditTrail:before {
  content: "\e90c";
}
.elpro-AuditTrail2:before {
  content: "\e90d";
}
.elpro-Back:before {
  content: "\e90e";
}
.elpro-Button-M{
  font-size: 24px;
}
.elpro-City:before {
  content: "\e90f";
}
.elpro-Close:before {
  content: "\e910";
}
.elpro-Company:before {
  content: "\e911";
}
.elpro-Country:before {
  content: "\e912";
}
.elpro-CreditCardCVC:before {
  content: "\e913";
}
.elpro-CreditCardName:before {
  content: "\e914";
}
.elpro-CreditCardNumber:before {
  content: "\e915";
}
.elpro-CreditCardValid:before {
  content: "\e916";
}
.elpro-DeviceView:before {
  content: "\21C9";
  font-size: 32px;
  line-height: 0
}
.elpro-Dashboard:before {
  margin: 0 0 0 5.5em;
  content: "\e917";
}
.elpro-ListView:before {
  font-size: 25px;
  vertical-align: top;
  margin: 1em 0 0 5.5em;
  content: "\2263";
}
.elpro-Delete:before {
  content: "\e918";
}
.elpro-Deviations:before {
  content: "\e919";
}
.elpro-Deviations2:before {
  content: "\e91a";
}
.elpro-Edit:before {
  content: "\e91b";
}
.elpro-Data-Grid-Delete:before {
  content: "\e918";
}
.elpro-Data-Grid-Edit:before {
  content: "\e91b";
}
.elpro-Data-Grid-Copy:before {
  content: "\e933";
}

.elpro-Export:before {
  content: "\e91c";
}
.elpro-HumidityIndicator .path1:before {
  content: "\e91d";
  color: rgb(255, 255, 255);
}
.elpro-HumidityIndicator .path2:before {
  content: "\e91e";
  margin-left: -0.927734375em;
  color: rgb(80, 175, 71);
}
.elpro-HumidityIndicator .path3:before {
  content: "\e91f";
  margin-left: -0.927734375em;
  color: rgb(255, 255, 255);
}
.elpro-HumidityIndicator .path4:before {
  content: "\e920";
  margin-left: -0.927734375em;
  color: rgb(255, 255, 255);
}
.elpro-HumidityIndicator .path5:before {
  content: "\e921";
  margin-left: -0.927734375em;
  color: rgb(255, 255, 255);
}
.elpro-HumidityIndicator .path6:before {
  content: "\e922";
  margin-left: -0.927734375em;
  color: rgb(58, 58, 57);
}
.elpro-HumidityIndicator .path7:before {
  content: "\e923";
  margin-left: -0.927734375em;
  color: rgb(177, 179, 179);
}
.elpro-HumidityIndicator .path8:before {
  content: "\e924";
  margin-left: -0.927734375em;
  color: rgb(177, 179, 179);
}
.elpro-Login:before {
  content: "\e925";
}
.elpro-Logout:before {
  content: "\e926";
}
.elpro-Menu:before {
  content: "\e927";
}
.elpro-MobilePhone:before {
  content: "\e928";
}
.elpro-News1:before {
  content: "\e929";
}
.elpro-News2 .path1:before {
  content: "\e92a";
  color: rgb(110, 110, 110);
}
.elpro-News2 .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(254, 254, 254);
}
.elpro-Next:before {
  content: "\e92c";
}
.elpro-NoSignal .path1:before {
  content: "\e92d";
  color: rgb(217, 218, 218);
}
.elpro-NoSignal .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-NoSignal .path3:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-NoSignal .path4:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-NoSignal .path5:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-Password:before {
  content: "\e932";
}
.elpro-Report:before {
  content: "\e933";
}
.elpro-Scan:before {
  content: "\e934";
}
.elpro-Search1:before {
  content: "\e935";
}
.elpro-Search2:before {
  content: "\e936";
}
.elpro-Search3 .path1:before {
  content: "\e937";
  color: rgb(110, 110, 110);
}
.elpro-Search3 .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.elpro-Settings:before {
  content: "\e939";
}
.elpro-SignalStrength0 .path1:before {
  content: "\e93a";
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength0 .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength0 .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength0 .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength1 .path1:before {
  content: "\e93a";
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength1 .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength1 .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength1 .path4:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-SignalStrength2 .path1:before {
  content: "\e93e";
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength2 .path2:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength2 .path3:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-SignalStrength2 .path4:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-SignalStrength3 .path1:before {
  content: "\e942";
  color: rgb(217, 218, 218);
}
.elpro-SignalStrength3 .path2:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-SignalStrength3 .path3:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-SignalStrength3 .path4:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-SignalStrength4:before {
  content: "\e946";
}
.elpro-TemperatureIndicator .path1:before {
  content: "\e947";
  color: rgb(255, 255, 255);
}
.elpro-TemperatureIndicator .path2:before {
  content: "\e948";
  margin-left: -0.9267578125em;
  color: rgb(80, 175, 71);
}
.elpro-TemperatureIndicator .path3:before {
  content: "\e949";
  margin-left: -0.9267578125em;
  color: rgb(255, 255, 255);
}
.elpro-TemperatureIndicator .path4:before {
  content: "\e94a";
  margin-left: -0.9267578125em;
  color: rgb(255, 255, 255);
}
.elpro-TemperatureIndicator .path5:before {
  content: "\e94b";
  margin-left: -0.9267578125em;
  color: rgb(255, 255, 255);
}
.elpro-TemperatureIndicator .path6:before {
  content: "\e94c";
  margin-left: -0.9267578125em;
  color: rgb(58, 58, 57);
}
.elpro-TemperatureIndicator .path7:before {
  content: "\e94d";
  margin-left: -0.9267578125em;
  color: rgb(177, 179, 179);
}
.elpro-TemperatureIndicator .path8:before {
  content: "\e94e";
  margin-left: -0.9267578125em;
  color: rgb(177, 179, 179);
}
.elpro-Username:before {
  content: "\e94f";
}
.elpro-WarningAcknowledged .path1:before {
  content: "\e950";
  color: rgb(110, 110, 110);
}
.elpro-WarningAcknowledged .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.elpro-WarningAcknowledged .path3:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.elpro-WarningActive .path1:before {
  content: "\e953";
  color: rgb(255, 206, 0);
}
.elpro-WarningActive .path2:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-WarningActive .path3:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-WarningActive .path4:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(58, 58, 57);
}
.elpro-ZipCode:before {
  content: "\e957";
}
.elpro-SortAlphaAsc:before {
  content: "\ea48";
}
.elpro-SortAlphaDesc:before {
  content: "\ea49";
}
