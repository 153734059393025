.ComponentRow {
    margin-bottom: 1rem;
}

.ComponentRow:last-of-type {
    margin-bottom: 0;
}

.TableFooter {
    font-weight: bold;
    background-color: #fafafa;
}
