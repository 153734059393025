.multiselect {
    position: relative;

    select {
        position: absolute;
        height: 100%;
    }
    .displayed-control {
        position: relative;
        height: auto;
        min-height: 2.1875rem
    }

    &.disabled {
        .displayed-control {
            background-color: #f9f9f9;

            .list-control-items .remove {
                pointer-events: none !important;
            }
        }
    }
}

.displayed-control {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    padding: 3px 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}