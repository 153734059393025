/* =======================================================================
    Component: Nested Accordian
========================================================================= */

.accordion__multiple-items {
    .accordion__title,
    .ant-collapse-header {
        padding: 0 !important;
        margin: 0;
        background-color: #fff;
    }

    .accordion__title:hover {
        background-color: #f8f8f8;
    }

    .accordion__body {
        padding: 0 0 0 15px;
    }

    .ant-collapse,
    .ant-collapse-item,
    .ant-collapse-content,
    .ant-collapse-content-box {
        padding: 0 !important;
        border: 0 !important;
        border-radius: 0 !important;
        background-color: unset;
    }

    .ant-collapse-content-box {
        padding-left: 15px !important;
    }

    .accordion__arrow {
        left: 0;
        transform: rotate(-90deg);
    }
    [aria-expanded='true'] .accordion__arrow,
    [aria-selected='true'] .accordion__arrow {
        transform: rotate(180deg);
    }

    .ant-collapse-arrow {
        z-index: 5;
    }
}

/* =======================================================================
    Component: Dashboard Accordian
========================================================================= */
.dashboard_accordian {
    position: relative;
    margin-top: 10px;

    .dashboard_accordian {
        // padding-left: 8px;
        padding-left: 0;
        margin-top: 30px;
    }

    .g-col-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .g-col-2 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .ant-collapse {
        border: none;
        background-color: inherit;
    }

    button.save-order {
        position: absolute;
        top: -10px;
        right: 18px;
        z-index: 5;
    }

    .ant-collapse-item {
        padding: 0 5px;
        margin-bottom: 15px;
        border: 2px solid #727272;
        border-top-width: 0;
        border-radius: 5px 0 3px 3px !important;

        .ant-collapse-header {
            padding-top: 0;
            padding-left: 14px;
            cursor: unset;

            .ant-collapse-arrow {
                left: -5px;
                top: 18px;
                transform: translateY(-50%)
            }
        }
    }

    .ant-collapse-content {
        border: none;
    }

    .accordion__clickable-title {
        position: relative;
        padding: 0px;
        border: none;
        cursor: default;

        &:before,
        &:after {
            content: '';
            position: absolute;
        }

        &:before {
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 22px;
            background-color: #727272;
        }

        &:after {
            width: 2px;
            height: 30px;
            top: 0;
            right: -23px;
            background-color: #fff;
        }

        .accordion__title-text {
            display: inline-block;
            position: relative;
            min-width: 120px;
            top: 1px;
            padding: 9px 15px 6px 15px;
            margin-left: 5px;
            border-style: solid;
            border-color: #727272;
            border-width: 2px 2px 0 0;
            border-top-right-radius: 4px;
            background-color: #ffffff;
            cursor: pointer;
            z-index: 5;

            &:before,
            &:after {
                content: '';
                position: absolute;
                box-sizing: content-box;
            }
            &:before {
                width: 36px;
                height: 2px;
                top: -4px;
                left: -22px;
                border-top: 2px solid #fff;
                background-color: #727272;
            }

            &:after {
                width: 2px;
                height: 1px;
                bottom: 0px;
                right: -2px;
                background-color: #ffffff;
            }
        }
    }

    .ant-collapse-content-box {
        padding: 0px 15px 10px 10px;

        .accordion__sensors-wrapper {
            margin: 0;
            padding: 10px;
            margin-bottom: 5px;
            border: 2px solid #b1b3b3;
            border-radius: 3px;

            .nodata_text {
                width: 100%;
                text-align: center;
                margin: 0;
            }
        }
    }
}

@media (min-width: 1024px)
{
    .dashboard_accordian {
        .g-col-4 {
            flex: 0 0 50%;
            max-width: 50%;
        }
        .g-col-2 {
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
}

@media (min-width: 1365px)
{
    .dashboard_accordian {
        .g-col-4 {
            flex: 0 0 33.33334%;
            max-width: 33.33334%;
        }
        .g-col-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }
    }
}


.accordion__clickable-title {
    padding: 16px 10px 16px 40px;
    margin-bottom: 5px !important;
    border: 1px solid #efefef;
    cursor: pointer;
}

.action__btns {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    &.sort_icon {
        right: 0;
    }
    &.sort_icon em {
        font-size: 20px;
        padding: 14px 30px;
    }
    &.sort_icon em:hover {
        background-color: #efefef;
    }
}

/* =======================================================================
    Component: ant MultSelect dropdown
========================================================================= */
.dashboard_ant-select {
    display: flex;
    float: right;
    margin: 15px 0 30px;

    .ant-select-selector {
        height: 35px;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            height: 4px;
            border-radius: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #d4d4d4;
            border-radius: 4px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #cecece;
        }

        .ant-select-selection-overflow {
            flex: none;
            flex-wrap: nowrap;
        }
    }
}

.ant-select-multiple .ant-select-selection-item-remove {
    line-height: 1.6;
}

.ant-select-tree-indent-unit {
    width: 8px !important;
}
