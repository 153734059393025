
.chars__counter {
    position: absolute;
    top: 48px;
    right: -48px;
    transform: translate(-50%, -50%);

    &--circle {
        position: relative;
        top: -1px;
        
        fill: none;
        stroke: #3a3a39;
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-dasharray: 0 999;

        transform: rotate(-90deg);
        transition: all .175s ease-out;

        z-index: 3;

        &.red--circle {
            stroke: #f61214;
        }
    }

    &--empty {
        position: absolute;
        left: 0;
        top: 0;
        fill: none;
        stroke: #b1b3b3;
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-dasharray: 999 999;
        z-index: 2;
    }

    &--number {
        position: absolute;
        width: 100%;
        top: 10px;
        left: 0;
        font-size: 11px;
        font-weight: 700;
        text-align: center;
        z-index: 4;
    }
}
