::-webkit-input-placeholder {
    font-style: italic;
}
:-moz-placeholder {
    font-style: italic;
}
::-moz-placeholder {
    font-style: italic;
}
:-ms-input-placeholder {
    font-style: italic;
}