.ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
}

.multizoneTable table {
    width: auto;
}

.multiZoneLimitHigh {
    /* width: 4em; */
    /* verticalGap:0;
    paddingTop:0;
    indicatorGap:0; */
    height: 0em;
    position: relative;
    bottom: -1.2em;
}

.multiZoneLimitLow {
    /* width: 4em; */
    height: 0em;
    position: relative;
    bottom: +1.8em;
}
/* .Button {
    margin: 2px;
    width: 30px;
  } */

/* .ant-input-number{
    box-shadow: none;
  } */

/* .bshadow0, input {
     box-shadow: none !important;
} */

/* input.focus {
  box-shadow : none !important;
} */

/* button {
  min-width: 30px;
} */

.H4limit {
    background: #ff9d9d !important;
}

.H3limit {
    background: #ffb2b2 !important;
}

.H2limit {
    background: #ffd3d3 !important;
}

.H1limit {
    background: #ffeded !important;
}

.Glimit {
    background: #f5fff5 !important;
}

.L1limit {
    background: #ededff !important;
}

.L2limit {
    background: #d8d8ff !important;
}

.L3limit {
    background: #c5c5ff !important;
}

.H4warning {
    background: #ffdd55 !important;
}

.H3warning {
    background: #ffe680 !important;
}

.H2warning {
    background: #ffeeaa !important;
}

.H1warning {
    background: #fff6c3ff !important;
}

.Gwarning {
    background: #fff6d3ff !important;
}

.L1warning {
    background: #fff6c3ff !important;
}

.L2warning {
    background: #ffeeaa !important;
}

.L3warning {
    background: #ffe680 !important;
}
