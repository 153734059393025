
.lcd-display {
    display: inline-block;
    margin-right: 18px;
    margin-bottom: 10px;
    padding-top: 0;

    &:last-child {
        margin-bottom: 30px;
    }

    span.lcd-segment {
        display: inline-block;
        position: relative;
        width: 22px;
        height: 28px;
        margin-right: 5px;
        text-align: center;

        .lcd-segment__el {
            position: absolute;
            background-color: #e4e4e4;
            border-radius: 2px;

            &.fill {
                background-color: #3a3a39 !important;
                box-shadow: 0 0 2px rgba(58, 58, 57, 0.4);
            }

            &:nth-child(1),
            &:nth-child(4) {
                width: calc(100% - 4px);
            }

            &:nth-child(10),
            &:nth-child(11) {
                width: calc(50% - 2px);
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(12),
            &:nth-child(13),
            &:nth-child(14) {
                width: 2px;
            }

            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(10),
            &:nth-child(11) {
                height: 2px;
            }
            
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(12),
            &:nth-child(13),
            &:nth-child(14) {
                height: calc(50% - 2px);
            }

            // Position
            &:nth-child(1) { top: -2px; left: 2px; }
            &:nth-child(2) { top: 0; right: 0; }
            &:nth-child(3) { top: calc(50% + 1px); right: 0; }
            &:nth-child(4) { top: calc(100% - 1px); left: 2px; }
            &:nth-child(5) { top: calc(50% + 1px); left: 0; }
            &:nth-child(6) { top: 0; left: 0; }
            &:nth-child(7) { top: 2px; left: 2px; transform: rotate(-30deg); transform-origin: top left; }
            &:nth-child(8) { top: 0; left: calc(50% - 1px); height: calc(50% - 1px);}
            &:nth-child(9) { top: 2px; right: 2px; transform: rotate(30deg); transform-origin: top right; }
            &:nth-child(10) { top: calc(50% - 1px); left: 1px; }
            &:nth-child(11) { top: calc(50% - 1px); left: calc(50% + 1px); }
            &:nth-child(12) { top: 50%; left: 2px; transform: rotate(30deg); transform-origin: bottom left; }
            &:nth-child(13) { top: calc(50% + 1px); left: calc(50% - 1px); }
            &:nth-child(14) { top: 50%; right: 2px; transform: rotate(-30deg); transform-origin: bottom right; }

        }

        .lcd-comma,
        .lcd-points  {
            background-color: #3a3a39;
            position: absolute;
            bottom: 2px;
            right: -4px;
            width: 3px;
            height: 4px;
        }
        
        .lcd-points:after  {
            content: '';
            background-color: inherit;
            position: absolute;
            top: -8px;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}


@media (min-width: 992px)
{
    .lcd-display {
        margin-bottom: 0;
        padding-top: 32px;

        &:first-child {
            margin-left: 35px;
        }
    }
}