.SettingsSaveRow {
	height: 100%;

	button {
		margin: auto;
	}
}

.MenuCell {
	padding: 0 !important;
	text-align: center;
}
