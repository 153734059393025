:root {
    --row-height: 3rem;
    --row-margin: 0.5rem;
    --cell-offset: calc(var(--row-height) / 2);
}

.Row {}

.Row div {
    margin: auto var(--row-margin);
}

.ContentRow {
    height: var(--row-height);
}

.HeaderRow {
    height: 2rem;
    background-color: #fafafa;
}


.RowH4 {
	background: #ff9d9d;
}

.RowH3 {
	background: #ffb2b2;
}

.RowH2 {
	background: #ffd3d3;
}
.RowH1 {
    background-color: #ffeded;
}

.RowG {
    background-color: #f5fff5;
}

.RowL1 {
    background-color: #ededff;
}

.RowL2 {
	background: #d8d8ff;
}

.RowL3 {
	background: #c5c5ff;
}

.CellLimitH {
    translate: 0 var(--cell-offset);
}

.CellLimitL {
    translate: 0 calc(var(--cell-offset) * -1);
}
