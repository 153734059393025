
$aside-bg: #3a3a39;

$content-bg: white;

$nav-top-bg: #fff;
$nav-top-item-desktop: #f61214;

$sidebar-bg: $aside-bg;
$sidebar-item-fg: #fefefe;
$sidebar-item-bg-active: darken($sidebar-bg, 10%);
$sidebar-heading-color: #919DA8;
$sidebar-highlight: lighten($sidebar-bg, 10%);
$sidebar-selected: lighten($sidebar-bg, 5%);
$sidebar-information: #919DA8;

$menu-item: $sidebar-bg;
$menu-fg: $sidebar-item-fg;
$menu-sub-item: darken($menu-item, 5%);
$menu-item-selected: lighten($menu-item, 5%);
$menu-item-active: darken($menu-item, 10%);

$view-header-bg: #b1b3b3;
