/* ========================================================================
     Component: List with draggable items
 ========================================================================== */

.draggable-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.draggable-list__item {
    position: relative;

    span.list_item {
        display: block;
        padding: 10px 15px;
        margin-bottom: 5px;
        border: 1px dotted #dadada;
        transition: transform .15s ease-in-out;
    }

    &.dragged {
        transform: scale(1.025);
    }

    &.can_drop {
        background-color: #fbfbfb;
        
        &.droppable {
            background-color: #f5f5f5;
        }
    }
}

.dashboard__drag-- {
    &item {
        position: relative;

        .dashboard__drag--item {
            margin-top: 15px;
        }

        &:last-child .ant-collapse-item {
            margin-bottom: 5px;
        }
    }

    &ref {
        position: absolute;
        top: 1px;
        left: 0;
        height: 38px;
        width: 40px;

        background-color: transparent;
        border: 0;
        outline: 0;
        z-index: 8;
        cursor: grab;

        span {
            position: absolute;
            display: inline-block;
            width: 4px;
            height: 4px;
            left: 10px;
            background-color: #3a3a39;

            &:nth-child(1) {
                top: 10px;
            }

            &:nth-child(2) {
                top: 17px;
            }
            &:nth-child(3) {
                top: 24px;
            }
            
            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background-color: inherit;
            }

            &:before {
                left: 7px
            }

            &:after {
                left: 14px
            }
        }
    }
}