.Header {
    background-color: #b1b3b3;
    padding: 10px;
    height: 4rem;
}

.Header h1 {
    color: #3a3a39;
    margin: auto;
    font-size: 1.5rem;
    font-weight: normal;
}
