.ag-theme-alpine {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;

    --ag-column-select-indent-size: 40px;
    --ag-foreground-color: rgb(0, 0, 0);
    --ag-background-color: rgb(255, 255, 255);

    --ag-header-background-color: rgb(240, 240, 240);
    --ag-odd-row-background-color: rgb(93, 156, 236, 0.03);
    --ag-header-column-resize-handle-color: rgb(126, 46, 132);
    --ag-icon-font-code-filter: rgb(0, 0, 0);
    --ag-header-column-separator-width: 2px;
    --ag-selected-row-background-color: rgba(248, 125, 103, 0.1);

    /*----Control panel----*/
    --ag-control-panel-background-color: rgb(255, 253, 253);
    --ag-side-button-selected-background-color: rgb(228, 197, 203);
    --ag-selected-tab-underline-color: rgb(255, 20, 20, 0.8);
    --ag-selected-tab-underline-width: 2px;
    --ag-selected-tab-underline-transition-speed: 0.5s;

    --ag-borders-input: dotted 2px;
    --ag-input-border-color: rgb(255, 20, 20, 0.5);

    --ag-checkbox-background-color: rgb(93, 156, 236, 0.03);
    --ag-checkbox-checked-color: rgb(93, 156, 236, 0.95);
    /* --ag-checkbox-unchecked-color: rgb(255, 20, 20, 0.04); */
    --ag-checkbox-indeterminate-color: rgb(93, 156, 236, 0.95);
    --ag-checkbox-unchecked-color: darkgrey;

    /*--------------------*/

    --ag-icon-font-family: 'Font Awesome 5 Free';

    --ag-card-radius: 10px;
    --ag-card-shadow: 0 10px 40px rgb(83, 0, 106);
    --ag-popup-shadow: var(--ag-card-shadow);
    --ag-tab-min-width: 350px;

    --ag-side-bar-panel-width: 300px;

    --ag-header-column-resize-handle-display: block;
    --ag-header-column-resize-handle-height: 25%;
    --ag-header-column-resize-handle-width: 5px;

    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-width: 2px;
    --ag-header-column-separator-color: purple;
}

/*----column-drop---- */
.ag-theme-alpine .ag-column-drop-cell {
    background-color: rgba(240, 9, 9, 0.06);
}

.ag-theme-alpine .ag-column-drop-cell .ag-icon {
    color: rgba(0, 0, 0, 0.5);
}

.ag-theme-alpine .ag-column-drop-cell-text {
    color: rgb(0, 0, 0);
    font-weight: bold;
}

/*--------------------*/

/*----header panel---- */
.ag-theme-alpine .ag-menu {
    background-color: rgb(255, 253, 253);
}

.ag-theme-alpine .ag-tabs-header {
    background-color: rgba(177, 179, 179, 0.5);
}

.ag-theme-alpine .ag-tabs-header .ag-icon {
    color: rgb(0, 0, 0);
}

.ag-theme-alpine .ag-icon,
.ag-theme-alpine .ag-checkbox-input-wrapper,
.ag-theme-alpine .ag-radio-button-input-wrapper {
    /* Font Awesome requires bold on all icon elements */
    font-weight: bold;
}

/* selectively replace the group and aggregation icons with Material Design Icons */
.ag-theme-alpine .ag-icon-group {
    --ag-icon-font-family: 'Material Design Icons';
    --ag-icon-font-code-group: '\F0328';
    font-weight: normal;
    color: red;
}

.ag-theme-alpine .ag-icon-aggregation {
    --ag-icon-font-family: 'Material Design Icons';
    --ag-icon-font-code-aggregation: '\F02C3';
    font-weight: normal;
    color: red;
}

/*--------------------*/

.ag-theme-alpine .ag-icon,
.ag-theme-alpine .ag-checkbox-input-wrapper,
.ag-theme-alpine .ag-radio-button-input-wrapper {
    /* Font Awesome requires bold on all icon elements */
    font-weight: bold;
}

/*State cell rules*/
.state-ok {
    background-color: rgb(82, 255, 108, 0.3) !important;
}

.state-warning {
    background-color: rgba(243, 255, 5, 0.3) !important;
}

.state-alarm {
    background-color: rgb(255, 20, 20, 0.3) !important;
}

.state-alarm-deactivated {
    background-color: rgb(217, 218, 218, 0.5) !important;
}

/*--------------------*/

/*Device name cell rules*/
.grid-device-name {
    color: #0a0d2b;
    font-weight: bold;
}

/*--------------------*/

/*Issue profile name cell rules*/
.grid-issue-profile-name {
    color: #0a0d2b;
    font-weight: normal;
}

/*--------------------*/

/*Blur row rules*/
.blurred {
    filter: blur(4px);
}

/*--------------*/

/*Menu cell rules*/
.menu-cell {
    overflow: visible !important;
}

/*--------------*/
