/* ========================================================================
     Component: list-control
 ========================================================================== */

.list-control-container {
    padding: 0;
    height: auto;
    min-height: 2.1875rem
}

.list-control-items {
    font-family: inherit;
    display: inline-block;
    line-height: 1;
    margin: 0 0.14285714em 0.14285714em;
    background-color: #f3f3f3;
    padding: 0.5833em 0.833em;
    color: rgba(0, 0, 0, 0.85);
    border-radius: 0.28571429rem;
    font-size: 0.8rem;

    .remove {
        font-weight: 700;
        position: relative;
        top: -1px;
        left: 2px;
        cursor: pointer;
    }
}

.list-control-input {
    display: inline-block;
    width: auto;
    height: 2.05rem;
    outline: none;
    border: none
}
