.ol_map-container {
	height: 400px;
	text-align: center;

    .ol_map {
        width: 100%;
        height: 400px;
	}

	.map_text {
		font-size:12px;
		line-height: 400px;
		font-weight:bold;
		color:#666666;
	}
}
.ol_map-popuap {
    display: none;
    position: absolute;
    width: max-content;
    top: 35px;
    left: 0;
    padding: 10px 15px;
    min-width: 50px;
    min-height: 10px;
	text-align: center;
    background-color: #fafafa;
    border-radius: 4px;
    transform: translate(-50%, -100%);
	box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.3);
	
	&.current_point {
		top: 15px;
	}

	&.show {
		display: block;
	}
	
	&:before, &:after {
		content: "";
		position: absolute;
		left: 50%;
		border: 10px solid transparent;
		transform: translateX(-50%);
	}
	&:before {
		border-top-color: #fafafa;
		bottom: -18px;
		z-index: 5;
	}
	&:after {
		border-top-color: rgba(99, 101, 98, 0.6);
		bottom: -22px;
		z-index: 4;
	}

	span {
		font-size: 12px;
		font-weight: 600;

		&.tempertaure {
			display: block;
			padding-top: 2px;
			font-size: 22px;
			font-weight: 700;
			line-height: 1;
		}
	}

	ul.tooltip_dates-list {
		margin: -5px -15px;
		padding: 15px 10px 10px 25px;
		max-height: 150px;
		overflow-y: scroll;
		list-style: circle;
		
		li {
			margin-bottom: 4px;
			font-size: 13px;
		}
	}
}

.speed_control {
    margin: 15px 0 0;

    label {
        margin: 0 10px;
        font-weight: 500;
    }

    input[type=range] {
        -webkit-appearance: none;
        margin: 5px;
        min-width: 160px;
        box-shadow: none;

        &:focus {
          outline: none;
        }
	}

	// Truck
	input[type=range]::-webkit-slider-runnable-track {
		background: #b1b3b3;
		height: 5px;
		border-radius: 3px;
		cursor: pointer;
	}
	input[type=range]::-moz-range-track {
		background: #b1b3b3;
		height: 5px;
		border-radius: 3px;
		cursor: pointer;
	}

	input[type=range]::-ms-track {
		width: 100%;
		height: 5px;
		border-radius: 3px;
		cursor: pointer;
		background: transparent; 
		border-color: transparent;
		color: transparent;
	}
	input[type=range]::-ms-fill-lower {
		background: #b1b3b3;
		border-radius: 3px;
	}
	input[type=range]::-ms-fill-upper {
		background: #b1b3b3;
		border-radius: 3px;
	}

	// Thumb
	input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 15px;
		width: 15px;
		background: #5d9cec;
		margin-top: -5px;
		border-radius: 50%;
		cursor: pointer;
	}
	input[type=range]::-moz-range-thumb  {
		height: 15px;
		width: 15px;
		background: #5d9cec;
		margin-top: -5px;
		border-radius: 50%;
		cursor: pointer;
	}
	input[type=range]::-ms-thumb {
		height: 15px;
		width: 15px;
		background: #5d9cec;
		margin-top: 0px;
		border-radius: 50%;
		cursor: pointer;
	  }

}
