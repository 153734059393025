@import "../../Layout/styles/colors";
@import "../../Layout/styles/variables";

.card {
	:global(.ant-card-head) {
		background-color: $view-header-bg;
	}
}

.card:not(:last-child) {
	margin-bottom: $view-element-margin;
}
