/**
Note, @import is deprecated und @use should be used instead. However, our sass is too old to parse @use.
 */

@import 'variables';
@import 'colors';

.layout {
	min-height: 100vh;
}

.header {
	position: sticky;
	top: 0;
	margin-bottom: 0;
	border-radius: 0;
	z-index: 1000;
	border: 0;
	padding: 0 2em;
	height: $navbar-height;
	background-color: $nav-top-bg !important;

	a,
	.sidebar-toggle {
		color: $nav-top-item-desktop;
		cursor: pointer;
	}
}

.headerRow {
	line-height: normal;
	height: 100%;
}

.sider {
	position: fixed !important;
	background-color: $sidebar-bg;
	color: $sidebar-information;
	overflow: auto;
	left: 0;
	top: $navbar-height;
	bottom: 0;

	> div {
		background-color: $sidebar-selected !important;
	}
}

.sidebar {
	height: 100%;
	padding: 1.5rem 0;
	background-color: $sidebar-bg;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch; // draw a divider for the sidebar

	// For easy alignment of the sidebar footer
	display: flex;
	flex-direction: column;

	:global(.ant-menu) {
		background-color: inherit;
	}

	:global(.ant-menu-sub) {
		background-color: $menu-sub-item !important;
	}

	:global(.ant-menu-item-selected) {
		background-color: $menu-item-selected;
	}

	:global(.ant-menu-item-icon) {
		display: inline !important;
		font-size: 24px !important;
		line-height: unset !important;
	}

	:global(.ant-menu-item:active),
	:global(.ant-menu-submenu-title:active),
	:global(.ant-menu-item:hover),
	:global(.ant-menu-submenu-title:hover) {
		background-color: $menu-item-active !important;
	}
}

.sidebarMenu {
	margin-bottom: 1em;
	color: $menu-fg;
}

.infoView {
	color: #919da8;
	letter-spacing: 0.035em;
	padding-left: 1em;
	font-size: 0.9em;
	list-style-type: none;

	a {
		pointer-events: auto;
		color: #919da8;
		&:hover {
			color: white;
			text-decoration: none;
		}
	}
}

.sidebarFooter {
	align-self: baseline;
}

.content {
	transition-duration: $transition-duration;

	> div:last-of-type {
		height: 100%;
	}
}

.viewWrapper,
.contentWrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.contentWrapper {
	background-color: $content-bg;
	padding: 16px;
}
