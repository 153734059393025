.toast-content {
    font-family: Verdana, sans-serif;

    .toast-content-pos {
        float: left;
        padding: 10px 0 0 10px;
        max-width: 90%;
    }

    h1 {
        font-size: 16px;
    }

    p {
    }

    .toast-icon {
        font-size: 25px;
        padding-right: 10px;
    }

    .error-message {
        color: #ffa39e;
        font-weight: bold;
    }
}

.toast-error-wrapper {
    background-color: #fff1f0 !important;
    border: 1px solid #ffa39e;
    color: rgba(0, 0, 0, 0.65) !important;
    border-radius: 4px !important;
    min-width: 600px !important;
}

.toast-success-wrapper {
    background-color: #f6ffed !important;
    border: 1px solid #b7eb8f;
    color: rgba(0, 0, 0, 0.65) !important;
    border-radius: 4px !important;
    min-width: 600px !important;
}

.toast-warning-wrapper {
    background-color: #ffea8c !important;
    border: 1px solid #ffd000;
    color: rgba(0, 0, 0, 0.65) !important;
    border-radius: 4px !important;
    min-width: 600px !important;
}

.toast-content {
    /*font-size: 40px;*/
}

.zoomOut {
    opacity: 0;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}

.zoomIn {
    transition: opacity 300ms ease-out, margin 300ms ease-in;
}
