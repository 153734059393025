// dashboard sensor card
.dashboard-sensor-card {
    transition: box-shadow 0.3s;
}

.dashboard-sensor-card:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

// sensor dashboard blur
.sensor-no-license-level1 {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(3px);
}

// confirmations
.ant-message-custom-content {
    display: flex;
    justify-content: center;
    font-size: 13px;
}

.error-debugger {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999999 !important;
    background-color: #fff;
    height: 300px;
    //overflow-y: auto;
}

.log-content {
    font-family: 'Consolas';
    font-size: 12px !important;
    color: red;
}

.alert-msg * {
    font-size: 13px !important;
}

.center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    margin: auto 20%;
}

// if the navigation is hidden, use this class for the section container
.section-container-without-sidebar {
    padding: 1px;
}

// if the navigation is hidden, use this class for the navbar header
.navbar-header-without-sidebar {
    padding: 10px 0 10px 10px;
}

.dashboardSensorErrorCodeToolTip {
    color: #FFFFFF !important;
    background-color: #F50 !important;
    font-size: 11px !important;
    pointer-events: auto !important;
    padding:3px !important;
}

.dashboardSensorErrorCodeToolTip :hover {
    visibility: visible !important;
    opacity: 1 !important;
}

.dashboardSensorErrorCodeToolTip:after {
    border-top-width:0 !important;
}

.alert-content-br-spacer {
    display: block;
    content: '';
    margin-top: 5px;
}

.important-link, .important-link:hover  {
    text-decoration: none;
    color:#E41919;
}

.important-link:hover  {
    text-decoration: underline;
    color:#E41919;
}