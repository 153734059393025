.moduleImageWWrapper {
    margin: 0 10px;
}

.moduleImageTitle {
    margin-top: -28px;
    font-size: 16px;
    text-align: center;
}

.selectedModule {
    box-shadow: 0 0 7px rgba(33, 33, 33, .2);
    border-width: 1px;
    border-color: red;
    border-style: solid;
}

.unSelectedModule {
    box-shadow: 0 0 0 rgba(33, 33, 33, .2);
    border-width: 1px;
    border-color: white;
    border-style: solid;
}

img.grayscale {
    filter:  grayscale(100%);
}

img.grayscale.disabled {
    filter: none;
}
